<template>
  <div
    class="is-flex is-flex-direction-column has-background-white px-5 py-5"
  >
    <b-loading v-model="is_loading" is-full-page/>
    <h1 class="has-text-centered is-size-4 has-text-weight-semibold">
      {{ device_name }}
    </h1>
    <hr>
    <div
      class="chart"
      ref="chartdiv"
    ></div>
    <div
      class="is-flex is-flex-wrap-wrap mt-3"
      v-if="is_available"
    >
      <div class="block has-background-white-ter p-4 is-flex is-flex-wrap-wrap is-justify-content-space-between" style="flex: 1 0 100%" v-if="markers.length > 0 || trend_marker_ranges.length > 0">
        <div>
          <div
            v-if="trend_marker_ranges.length > 0"
          >
            <b-checkbox
              class="mr-5"
              @input.native="handleTrendMarkerChange($event.target.checked)"
            >
              Показывать названия
            </b-checkbox>
          </div>
          <div v-if="markers.length > 0">
            <p class="has-text-weight-semibold mb-2" style="font-size: 18px">Markers</p>
            <b-checkbox
              v-for="(marker, idx) in markers"
              :key="`marker-${idx}`"
              v-model="selected_markers"
              :native-value="marker"
              class="mr-5"
              @input.native="handleMarkerChange($event.target.checked, marker)"
            >
              {{ marker }}
            </b-checkbox>
          </div>
        </div>
        <div>
          <p>Обороты: <strong>{{ speed }} об/мин</strong></p>
          <p>RMS: <strong>{{ rms }} <span v-html="rms_unit"></span></strong></p>
        </div>
      </div>
      <div style="width: 100%" class="mb-5">
        <div
          class="mr-4 px-4 pt-4 pb-2 is-flex has-background-light"
          style="width: 100%; gap: 1rem;"
        >
          <b-field label="Выберите тип" class="is-flex-grow-1">
            <b-select
              placeholder="Типы"
              v-model="selected_spectrum.type"
              expanded
              @input="selected_spectrum.channel && fetchSpectraSources()"
            >
              <option
                v-for="(type, idx) in types"
                :value="type"
                :key="idx"
              >
                {{ getTypeName(type) }}({{type}})
              </option>
            </b-select>
          </b-field>
          <b-field label="Выберите канал" class="is-flex-grow-1">
            <b-select
              placeholder="Каналы"
              v-model="selected_spectrum.channel"
              expanded
              @input="selected_spectrum.type ? fetchSpectraSources() : undefined"
            >
              <option
                v-for="(option, idx) in channels"
                :value="option"
                :key="idx"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Выберите источник" class="is-flex-grow-1">
            <b-select
              v-model="selected_spectrum.source"
              expanded
              :loading="sources_is_loading"
              :placeholder="sources.length === 0 ? 'пусто' : 'Выберите'"
            >
              <option
                v-for="(src, idx) in sources"
                :key="`s-${idx}`"
                :value="src"
              >{{ src }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="is-divider mb-4" />
    </div>
    <div class="is-flex is-justify-content-flex-end">
      <button
        v-if="selected_spectrum_id"
        class="button is-danger is-outlined mr-4"
        @click="openDeleteConfirm"
      >Удалить</button>
      <button
        class="button is-info"
        :disabled="!selected_spectrum.channel || !selected_spectrum.source || !selected_spectrum.type"
        @click="fetchSpectra"
      >Применить</button>
    </div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

am4core.useTheme(am4themes_animated)

export default {
  name: "SpectraList",
  data() {
    return {
      id: this.$route.params.id || 0,
      chart: null,
      is_loading: true,
      is_available: false,
      device_name: '',
      selected_spectrum: {
        source: undefined,
        channel: undefined,
        type: undefined
      },
      types: [],
      channels: [],
      sources: [],
      sources_is_loading: false,
      series: [],
      main_value_axis: undefined,
      trend_marker_ranges: [],
      markers: [],
      marker_ranges: [],
      selected_markers: [],
      selected_spectrum_id: undefined,
      trend_colors: ["#0091FF", "#6DD400", "#6236FF", "#ff00c8", "#9B9B9B", "#44D7B6", "#0D7B80"],
      rms: '',
      speed: '',
      rms_unit: 'm/s2'
    }
  },
  async mounted() {
    await this.fetchLastMessage()
    await this.fetchSpectraTypes()
    await this.createChart()
    this.is_loading = false
  },
  beforeDestroy() {
    this.chart.dispose()
  },
  methods: {
    createChart() {
      am4charts.ValueAxis.prototype.getSeriesDataItem = function(series, position) {
        var key = this.axisFieldName + this.axisLetter
        var value = this.positionToValue(position)
        return series.dataItems.getIndex(series.dataItems.findClosestIndex(value, function(x) {
          return x[key]
        }, "any"))
      }

      const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

      //ось X
      this.value_axis = chart.xAxes.push(new am4charts.ValueAxis())
      this.value_axis.strictMinMax = true
      //ось Y
      // const valueY = this.createValueAxis(chart, 'm/s2')
      // valueY.tooltip.disabled = true

      chart.cursor = new am4charts.XYCursor()
      // chart.legend = new am4charts.Legend();
      //верхний ползунок временного периода
      chart.scrollbarX = new am4core.Scrollbar()

      this.chart = chart
      this.chart.data = []
    },
    getTypeName(type) {
      return {
        env: 'Огибающая',
        vel: 'Скорость',
        acc: 'Ускорение'
      }[type]
    },
    async fetchLastMessage() {
      try {
        const { data } = await this.$axios.get(`user/agents/${this.id}/last-message`)
        this.device_name = data?.data?.device_name || ''
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchSpectraTypes() {
      this.is_loading = true
      try {
        const { data } = await this.$axios.get(`user/agents/${this.id}/spectrum-types`)
        this.is_available = data?.code === 200
        this.channels = data?.data?.channel || []
        this.types = data?.data?.type || []
      } catch (e) {
        throw new Error(e)
      }
      this.is_loading = false
    },
    async fetchSpectraSources() {
      this.sources = []
      this.selected_spectrum.source = undefined
      this.sources_is_loading = true
      try {
        const { data } = await this.$axios.post(`user/agents/${this.id}/spectrum-sources`, this.selected_spectrum)
        this.sources = data?.data?.source || []
      } catch (e) {
        throw new Error(e)
      }
      this.sources_is_loading = false
    },
    clearChart() {
      this.series = []
      this.sources = []
      this.markers = []
      this.marker_ranges = []
      this.trend_marker_ranges = []
      this.selected_spectrum_id = undefined
      this.selected_spectrum = {
        source: undefined,
        channel: undefined,
        type: undefined
      }
      this.chart.data = []
      this.value_axis.axisRanges.clear()

      const seriesCount = this.chart.series.length
      for (let i = 0; i < seriesCount; i++) {
        this.chart.series.removeIndex(0).dispose()
      }
      const axisCount = this.chart.yAxes.length
      for (let i = 0; i < axisCount; i++) {
        this.chart.yAxes.removeIndex(0).dispose()
      }
    },
    async fetchSpectra() {
      this.is_loading = true
      this.marker_ranges = []
      this.trend_marker_ranges = []
      // this.chart.dispose()
      // this.createChart()

      try {
        const { data } = await this.$axios.post(`user/agents/${this.id}/spectrums`, {
          spectrums: [ this.selected_spectrum ]
        })

        const graphData           = data?.['data'] || []
        const sourceNames         = data?.['names'] || []
        const trendNames          = data?.['trend_names'] || []
        const alarmWarningData    = data?.['alarm_warning'] || []
        const dataTypes           = data?.['types'] || []

        this.selected_spectrum_id = Object.keys(sourceNames)[0]

        this.rms = data?.['rms'] || 0
        this.speed = data?.['speed'] || 0

        this.chart.data = [
          ...graphData,
          ...(alarmWarningData.length ? alarmWarningData : [])
        ] || []
        this.value_axis.axisRanges.clear()

        const seriesCount = this.chart.series.length
        for (let i = 0; i < seriesCount; i++) {
          this.chart.series.removeIndex(0).dispose()
        }
        const axisCount = this.chart.yAxes.length
        for (let i = 0; i < axisCount; i++) {
          this.chart.yAxes.removeIndex(0).dispose()
        }

        const isVelocity = dataTypes[0] === 'vel'
        this.main_value_axis = this.createValueAxis(isVelocity ? 'mm/s' : 'm/s2')
        this.rms_unit = isVelocity ? 'mm/s' : 'm/s2'


        dataTypes.forEach((name, idx) => {
          // const valueAxis = name === 'vel' ? isVelocity ? this.main_value_axis : this.opposite_value_axis : this.main_value_axis
          // this.series_colors[idx] = this.trend_colors[idx]
          this.series = [
            ...this.series,
            this.createSeries(`source_${idx}_x`, `source_${idx}_y`, idx, data?.names?.length || 0, this.trend_colors[idx], name, true)
          ]

          if(alarmWarningData.length > 0 && idx === 0) {
            this.createSeries(`source_${idx}_x`, `alarm`, 0, sourceNames.length, '#FF0000', `alarm(${name})`, false)
            this.createSeries(`source_${idx}_x`, `warning`, 0, sourceNames.length, '#FFA500', `warning(${name})`, false)
          }
          if(trendNames.length > 0) {
            for (const trendName of trendNames) {
              const { name: trend_name, text } = trendName
              this.trend_marker_ranges = [...this.trend_marker_ranges, this.createRange(trendName[`source_${name}_x`], `${trend_name} | ${text}`, '#666', "top")]
            }

            this.handleTrendMarkerChange(false)
          }
        })

        const markerSet = new Set()
        const _markers = data?.["spectrum_markers"] || {}
        const markerKeys = Object.keys(_markers)

        if(markerKeys.includes('SPEED')) markerSet.add('SPEED')
        if(markerKeys.includes('BPFO')) markerSet.add('BPFO')
        if(markerKeys.includes('BPFI')) markerSet.add('BPFI')
        if(markerKeys.includes('BSF')) markerSet.add('BSF')
        if(markerKeys.includes('FTF')) markerSet.add('FTF')

        for (const key of markerKeys) {
          markerSet.add(key)
        }

        this.markers = [...markerSet]
        this.selected_markers = [...markerSet]
        // SPEED, BPFO, BPFI, BSF, FTF

        Object.entries(_markers).forEach(([key, value]) => {
          // const randomColor = "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")
          Object.entries(value).forEach(([mKey, mValue]) => {
            this.marker_ranges[key] = [...(this.marker_ranges[key] || []), this.createRange(mValue, mKey, '#999')]
          })
        })
      } catch (e) {
        throw new Error(e)
      }
      this.is_loading = false
      this.chart.validate()
      // await this.fetchReferenceSpectra()
    },
    async openDeleteConfirm() {
      await this.$buefy.dialog.confirm({
        message: 'Вы действительно хотите удалить выбранный спектр?',
        closeOnConfirm: true,
        cancelText: 'Отмена',
        confirmText: 'Да',
        onConfirm: () => { this.deleteSpectrum() },
      });
    },
    async deleteSpectrum() {
      try {
        await this.$axios.delete(`admin/spectrums/${this.selected_spectrum_id}`)
        this.clearChart()
        this.$buefy.toast.open({ message: 'Спектр удален' })
      } catch (e) {
        throw new Error(e)
      }
    },
    handleMarkerChange(event, key) {
      this.marker_ranges[key]?.forEach(range => event ? range?.show() : range?.hide())
      // if(this.selected_markers.includes(key) && this.marker_ranges[key])
      //   this.marker_ranges[key].hide()
      // else
      //   this.marker_ranges[key].show()
    },
    handleTrendMarkerChange(event) {
      this.trend_marker_ranges.forEach(marker => {
        if(event) marker?.show()
        else marker?.hide()
      })
    },
    createSeries(valueX, valueY, index, count, color, name, fillLine, valueAxis) {
      const series = this.chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueX = valueX
      series.dataFields.valueY = valueY
      series.stroke = color
      series.name = name || ''

      if(valueAxis) series.yAxis = valueAxis
      // else series.yAxis = this.main_value_axis

      // series.zIndex = 10
      series.align = "center"
      series.valign = "middle"
      series.tooltipText = "{name}: [bold]{valueY}[/]"
      // if(['alarm', 'warning'].includes(name)) {
      //   series.cursorTooltipEnabled = false
      // }
      // series.tooltipText = "{valueX}: [b]{valueY}[/]";
      series.tooltip.pointerOrientation = "vertical"
      series.tooltip.background.fillOpacity = 0.6
      // series.tooltipText = "[{stroke.hex}]●[/]{name}: [bold]{valueY}[/]"
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = color
      series.tooltip.label.fill = am4core.color("#000")
      series.strokeWidth = 1
      series.fill = series.stroke;
      series.fillOpacity = fillLine ? 0.14 : 0
      series.dx = this.chart.depth / (count) * am4core.math.cos(this.chart.angle) * index
      series.dy = -this.chart.depth / (count) * am4core.math.sin(this.chart.angle) * index
      return series
    },
    createValueAxis(text, isOpposite = false, min) {
      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.opposite = isOpposite
      valueAxis.showOnInit = true
      valueAxis.title.text = text
      valueAxis.title.rotation = 90
      valueAxis.title.valign = "top"
      valueAxis.title.fontWeight = '600'
      valueAxis.renderer.grid.template.strokeOpacity = 0.1
      valueAxis.min = !isNaN(min) ? min : undefined
      valueAxis.showOnInit = true
      // if(chart.yAxes.indexOf(valueAxis) !== 0){
      //   valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
      // }

      return valueAxis
    },
    createRange(value, text, color, align = "bottom") {
      const range = this.value_axis.axisRanges.create()

      range.value = value
      // range.endValue = value
      range.grid.above = true
      range.grid.stroke = color || am4core.color("#999")
      range.grid.strokeWidth = 2
      range.grid.strokeOpacity = 1
      range.label.inside = true
      range.label.text = text
      range.label.fill = range.grid.stroke
      range.label.rotation = -90
      range.label.valign = "top"
      range.label.verticalCenter = 'bottom'
      range.label.fontSize = 12
      range.label.fontWeight = "500"
      range.label.paddingBottom = 2

      if(align === 'bottom') {
        range.label.paddingRight = 100
        range.label.paddingBottom = -16
      } else if(align === 'top') {
        range.label.valign = 'middle'
        range.axisFill.tooltip = new am4core.Tooltip();
        range.axisFill.tooltipX = am4core.percent(50)
        range.axisFill.tooltipY = am4core.percent(0)
        range.axisFill.tooltipText = "[bold]{label.text}[]";
        range.axisFill.interactionsEnabled = true;
        range.axisFill.isMeasured = true;
      }

      return range
    }
  },
}
</script>

<style scoped lang="scss">
.chart {
  height: 500px;
  width: 100%;
}
</style>
